// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

var $ = require('jquery');
module.exports = {$: $};
var mui = require('./mui.min.js');
var sizeOfOneMinuteOfVideoInMegabytes = 28;
var megabytesOfBandwidthConsumedByOneMinuteOfVideo = 9;
var standardPlan = {
  maximumStorageInGigabytes: 100,
  maximumBandwidthInGigabytes: 400
};

var miniPlan = {
  maximumStorageInGigabytes: 20,
  maximumBandwidthInGigabytes: 20
};


$(function () {

  var mobileMenu = $('#mobile-menu');

  $('.smooth-slide').click(function () {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top - 80
        }, 1000);
        return false;
      }
    }
  });

  $('input[type=range]').change(function () {
    calculateUsage();
  });

  $('input[type=range]').on('input', function () {
    updateValues();
  });

  updateValues();
  calculateUsage();

  function updateValues() {
    var numberOfVideos = $('#inov').val();
    var averageVideoLength = $('#iavl').val();
    var videoPlaysPerMonth = $('#ivppm').val() * 10;
    if (videoPlaysPerMonth === 0) videoPlaysPerMonth = 1;

    var hours = Math.floor(averageVideoLength / 60);
    var minutes = averageVideoLength % 60;

    var time = '';
    if (hours == 0) {
      time = minutes + ' min';
    } else {
      if (minutes < 10) minutes = '0' + minutes;
      time = hours + 'h : ' + minutes + ' min';
    }

    $('#nov').html(numberOfVideos);
    $('#avl').html(time);
    $('#vppm').html(videoPlaysPerMonth);
  }

  function miniHumanize(megaBytes) {
    if (megaBytes > 1024) {
      megaBytes = Math.round(megaBytes / 1024) + ' TB';
    } else {
      megaBytes = megaBytes + ' GB';
    }
    return megaBytes;
  }

  function calculateUsage() {
    var numberOfVideos = $('#inov').val();
    var averageVideoLength = $('#iavl').val();
    var videoPlaysPerMonth = $('#ivppm').val() * 10;
    if (videoPlaysPerMonth === 0) videoPlaysPerMonth = 1;

    var storage = Math.round(numberOfVideos * averageVideoLength * sizeOfOneMinuteOfVideoInMegabytes / 1024);
    var bandwidth = Math.round(averageVideoLength * videoPlaysPerMonth * megabytesOfBandwidthConsumedByOneMinuteOfVideo / 1024);

    $('#storage').html(miniHumanize(storage));
    $('#bandwidth').html(miniHumanize(bandwidth));

    var suggestedPackage = suggestPackage(numberOfVideos, storage, bandwidth);
    var plan = $('#plan').html(suggestedPackage);
    var suggestedPlanHref = "#pricing-tables"
    if (suggestedPackage == 'Enterprise') {
      suggestedPlanHref = '#enterprise-plan'
    }
    plan.attr('href', suggestedPlanHref);
  }

  function suggestPackage(videos, storage, bandwidth) {
    var mini = 25;
    var standard = 35;
    var custom = 1000;

    var minpackage = mini;

    if (videos > 100) minpackage = standard;
    if (videos > 250) minpackage = custom;

    if (storage > 20) minpackage = Math.max(minpackage, standard);
    if (storage > 100) minpackage = Math.max(minpackage, custom);

    if (bandwidth > 20) minpackage = Math.max(minpackage, standard);
    if (bandwidth > 400) minpackage = Math.max(minpackage, custom);

    if (minpackage == custom) {
      return "Enterprise";
    } else {
      return "$" + minpackage + " Package";
    }
  }

  $('.hamburger').click(function (e) {
    e.preventDefault();
    if (mobileMenu.is(':visible')) {
      mobileMenu.slideUp("fast");
    } else {
      mobileMenu.slideDown("fast");
    }
  });

  // Log In or Sign Up depending on the params
  function login(params) {
    var email = $('#iplayerhd-email').val();
    var password = $('#iplayerhd-pass').val();
    var triggerBtn = params.triggerBtn;

    var errorMsg = '';

    if (!email.length || !password.length || email.length === 0 || password.length === 0) {
      errorMsg = 'Please fill out both email and password fields.';
    }

    if (!errorMsg) {
      triggerBtn.addClass('loading');
      disableSignupBtns();
      $.ajax({
        type: 'POST',
        url: params.url,
        data: {
          username: email,
          password: password
        },
        success: function (result) {
          triggerBtn.removeClass('loading');
          enableSignupBtns();
          params.successAction(result);
        },
        error: function (err, s, e) {
          triggerBtn.removeClass('loading');
          enableSignupBtns();
          if (err && err.status == 401) {
            showSignupErrorMsg(genericLoginError())
          } else {
            showSignupErrorMsg(err && err.responseJSON && err.responseJSON.message);
          }

        }
      });
    }
    showSignupErrorMsg(errorMsg);
  }

  function afterLoginRedirect() {
    window.open('/cms/site/#/upload', '_self');
  }

  function afterSignupRedirect(result) {
    fbq('track', 'CompleteRegistration', {
      value: 25.00,
      currency: 'USD'
    });

    window.open('/cms/login/activate-trial/' + result.activationCode, '_self');
  }

  // Handles disabling of all sign up buttons
  function disableSignupBtns() {
    $('.sign-up-btn').each(function() {
      $(this).prop('disabled', true);
    });
  }

  // Handles enabling of all sign up buttons
  function enableSignupBtns() {
    $('.sign-up-btn').each(function() {
      $(this).prop('disabled', false);
    });
  }

  function showSignupErrorMsg(message) {
    var signupErrors = $('.sign-up-errors');
    if (message) {
      signupErrors.html('<p>' + message + '</p>');
    } else {
      signupErrors.empty();
    }
  }

  $('#sign-up-btn').click(function (e) {
    e.preventDefault();
    login({
      url: '/cms/login/signup',
      triggerBtn: $(this),
      successAction: afterSignupRedirect
    });
  });

  $('#iplayerhd-signup-form input').keydown(function (e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      login({
        url: '/cms/login/signup',
        triggerBtn: $("#sign-up-btn"),
        successAction: afterSignupRedirect
      });
    }
  });

  $('#log-in-btn').click(function (e) {
    e.preventDefault();
    login({
      url: '/cms/login',
      triggerBtn: $(this),
      successAction: afterLoginRedirect
    });
  });

  $('#iplayerhd-login-form input').keydown(function (e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      login({
        url: '/cms/login',
        triggerBtn: $("#log-in-btn"),
        successAction: afterLoginRedirect
      });
    }
  });

  function redirectUser() {
    window.open('/cms/site/#/welcome', '_self');
  }

  // Google Signup / Login

  $('#sign-up-btn-g').click(function (e) {
    e.preventDefault();
    disableSignupBtns();
    googleLogin({
      url: '/cms/login/google/signup',
      afterSuccessRedirect: redirectUser,
      signup: true
    });
  });

  $('#log-in-btn-g').click(function (e) {
    e.preventDefault();
    disableSignupBtns();
    googleLogin({
      url: '/cms/login/google',
      afterSuccessRedirect: afterLoginRedirect,
      signup: false
    });
  });

  function googleLogin(params) {
    showSignupErrorMsg();
    if (!gapi || !gapi.auth2) return;
    var gauth = gapi.auth2.getAuthInstance();
    if (gauth.isSignedIn.get()) {
      gSignInProceed({
        user: gauth.currentUser.get(),
        url: params.url,
        signup: params.signup,
        afterSuccessRedirect: params.afterSuccessRedirect
      });
    } else {
      gauth.signIn().then(function () {
        gSignInProceed({
          user: gauth.currentUser.get(),
          url: params.url,
          signup: params.signup,
          afterSuccessRedirect: params.afterSuccessRedirect
        });
      }, function (error) {
        showSignupErrorMsg(error.message);
      });
    }
  }

  function gSignInProceed(params) {
    var id_token = params.user.getAuthResponse().id_token;
    $.ajax({
      url: params.url,
      type: 'POST',
      data: {
        id_token: id_token
      },
      success: function () {
        params.afterSuccessRedirect();
        enableSignupBtns();
      },
      error: function (err) {
        if (!params.signup && err && err.status == 401) {
          showSignupErrorMsg(accountNotFound());
        } else {
          showSignupErrorMsg(accountAlreadyExistsOrSocialNotConnected());
        }
        enableSignupBtns();
      }
    });
  }

  function genericLoginError() {
    return 'Invalid username and/or password. Did you mean to <a href="/signup">sign up</a> instead?';
  }

  function accountNotFound() {
    return 'Account not found. Did you mean to <a href="/signup">sign up</a> instead?';
  }

  function accountAlreadyExists() {
    return 'Account already exists. Did you mean to <a href="/login">log in</a> instead?';
  }

  function accountAlreadyExistsOrSocialNotConnected() {
    return accountAlreadyExists() + '<br />' +
      'If you are an existing user, you must <a href="/login">log in</a> with your username/password and ' +
      'connect your iPlayerHD account to a 3rd party account to use for signing in. ' +
      '</div>';
  }

  // Facebook Login / Signup
  $('#sign-up-btn-fb').click(function (e) {
    e.preventDefault();
    disableSignupBtns();
    facebookLogin({
      url: '/cms/login/facebook/signup',
      afterSuccessRedirect: redirectUser,
      signup: true
    });
  });

  $('#log-in-btn-fb').click(function (e) {
    e.preventDefault();
    disableSignupBtns();
    facebookLogin({
      url: '/cms/login/facebook',
      afterSuccessRedirect: afterLoginRedirect,
      signup: false
    });
  });

  function facebookLogin(params) {
    showSignupErrorMsg();
    if (!FB) return;
    FB.login(function (response) {
      if (response.status === 'connected') {
        var token = response.authResponse.accessToken;
        $.ajax({
          type: 'POST',
          url: params.url,
          data: {
            access_token: token
          },
          success: function () {
            params.afterSuccessRedirect();
            enableSignupBtns();
          },
          error: function (err) {
            enableSignupBtns();
            if (params.signup) {
              showSignupErrorMsg(accountAlreadyExistsOrSocialNotConnected());
            } else {
              showSignupErrorMsg(accountNotFound());
            }
          }
        });
      } else if (response.status === 'not_authorized') {
        showSignupErrorMsg('You haven\'t authorized the iPlayerHD application to fetch your data from Facebook.');
      } else {
        showSignupErrorMsg('Facebook login error.');
      }
      enableSignupBtns();
    }, {scope: 'public_profile,email'});
  }

  // Reset Password
  $('#reset-password-btn').click(function (e) {
    e.preventDefault();
    disableSignupBtns();
    resetPassword({
      triggerBtn: $(this)
    });
  });

  function resetPassword(params) {
    var email = $('#iplayerhd-email').val();
    var contentArea = $('#reset-password-content');
    var errorMessageElement = $('#error-message');
    var triggerBtn = params.triggerBtn;

    var errorMsg = '';

    if (!email.length || email.length === 0) {
      errorMsg = 'Please fill out the email field.';
    }

    if (!errorMsg) {
      triggerBtn.addClass('loading');
      disableSignupBtns();
      $.ajax({
        type: 'POST',
        url: '/cms/login/forgot',
        data: {
          username: email
        },
        success: function () {
          triggerBtn.removeClass('loading');
          contentArea.text('We\'ve sent you an email with a link to reset your password.')
        },
        error: function (err, s, e) {
          triggerBtn.removeClass('loading');
          errorMessageElement.text('Invalid email.');
          enableSignupBtns();
        }
      });
    }
  }


  function checkFirstYearFreeAvailability() {
    var url = '/cms/free-year-trial-check';
    $.ajax({
      url: url,
      type: 'GET',
      success: function (data) {
        var dimensionValue = 'true';
        if (!data.freeYearTrial) {
          $('.fyf-availability').text('Unfortunately iPlayerHD\'s first year free offer is not available in your country. Feel free to continue signing up for our 14-day free trial.').addClass('notice');
          dimensionValue = 'false';
          $('#fyf-promo').hide();
        }
        ga('set', 'dimension3', dimensionValue);
      },
      error: function (err) {
      }
    });
  }

  function checkSignupAvailability() {
    var url = '/cms/signup-check';
    $.ajax({
      url: url,
      type: 'GET',
      success: function (data) {
        if (!data.canSignup) {
          $('.fyf-availability').hide();
          $('.signup-availability').text('Unfortunately we do not currently offer iPlayerHD in your country.').addClass('notice');
        } else {
          $('#signup-area').show();
          checkFirstYearFreeAvailability();
        }
      },
      error: function (err) {
      }
    });
  }

  checkSignupAvailability();

  $('iframe').each(function(){
    var iframe = this;
    var player = new playerjs.Player(iframe);
    var once = false;
    player.on(playerjs.EVENTS.PLAY, function(){
      if (!once) {
        once = true;
        var src = player.elem.src;
        ga('send', 'event', 'Video', 'play', src);
      }
    });
  });

  $('#reactivateAccount').click(function(e) {
    e.preventDefault();

    var reactivationCode = getParameterByName('u');

    var password = $('#iplayerhd-pass').val();
    var repeatPassword = $('#iplayerhd-pass-repeat').val();

    if (password !== repeatPassword) {
      showSignupErrorMsg('Repeat password doesn\'t match');
      return;
    }

    var href = $(this).attr('href');

    var url = '/cms/login/convert-to-free-year';
    var href = $(this).attr('href');
    $.ajax({
      url: url,
      type: 'PUT',
      data: {reactivationCode: reactivationCode, password: password},
      success: function () {
        window.open(href, '_self');
      },
      error: function (err) {
        if (err && err.responseText) {
          $('#reactivateArea').text(err.responseText);
        }
      }
    });

  });

  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  $('.togglePassVisibility').click(function(e) {
    e.preventDefault();
    var togglePassVisibilityBtn = $(this);
    var passField = togglePassVisibilityBtn.prev('input');
    var passFieldType = passField.prop('type');

    if (passFieldType !== 'text') {
      passField.prop('type', 'text');
      $(this).addClass('on');
    } else {
      passField.prop('type', 'password');
      $(this).removeClass('on');
    }

  });

  function checkReactivateEligibility() {
    var reactivationCode = getParameterByName('u');
    var url = '/cms/login/check-legacy/' + reactivationCode;

    var reactivateAccBtn = $('#reactivateAccount');
    var href = reactivateAccBtn.attr('href');

    $.ajax({
      url: url,
      type: 'GET',
      success: function (data) {
        $('#iplayerhd-email').val('Username: ' + data.username);
        reactivateAccBtn.attr({'href': href + '?u=' + encodeURIComponent(data.username)});
        $('#fyfEligibility').fadeOut();
        $('#reactivateFyfForm').removeClass('blurry');
      },
      error: function (err) {
        if (err && err.responseText) {
          $('#reactivateArea').text(err.responseText);
        }
      }
    });

  }

  // Execute only if page is landing/reactivate-trial
  if ($.inArray(top.location.pathname, ['/landing/reactivate-trial', '/landing/reactivate-trial.html']) > -1)
  {
    setTimeout(checkReactivateEligibility, 2000);
  }


  function prefilUsernameFromParams() {
    var username = getParameterByName('u');
    if (username) {
      $('#iplayerhd-email').val(username);
    }
  }

  // Execute only if page is /login
  if ($.inArray(top.location.pathname, ['/login', '/login.html']) > -1)
  {
    prefilUsernameFromParams();
  }

  function appendUsernameToLoginBtn() {
    var username = getParameterByName('u');
    console.log(username);
    if (username) {
      var loginBtn = $('#loginAfterReactivation');

      $('#loginAfterReactivation').attr({'href': loginBtn.attr('href') + '?u=' + encodeURIComponent(username)});
    }
  }

  // Execute only if page is /login
  if ($.inArray(top.location.pathname, ['/landing/trial-reactivated', '/landing/trial-reactivated.html']) > -1)
  {
    appendUsernameToLoginBtn();
  }


});

